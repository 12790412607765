import React from 'react';
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import {useSelector} from 'react-redux';

import Login from 'pages/login';
import SignUp from 'pages/sign-up/sign-up';

import Maps from 'pages/map';
import MapAdd from 'pages/map/add';
import MapEdit from 'pages/map/edit';
import MapId from 'pages/map/id';
import MapField from 'pages/map/field';

import Inventory from 'pages/inventory';
import InventoryAdd from 'pages/inventory/add';
import InventoryEdit from 'pages/inventory/edit';

import JournalAmount from 'pages/journals/amount';
import JournalSpread from 'pages/journals/spread';

import Supplies from 'pages/supplies';
import SuppliesId from 'pages/supplies/id';
import SuppliesNew from 'pages/supplies-new/supplies-new';

import Realizations from 'pages/realizations';
// import RealizationsNew from 'pages/realizations-new/realizations-new';
import New from 'pages/realizations/new';
import RealizationEdit from 'pages/realizations/edit/[id]';

import Form from 'pages/form';
import FormId from 'pages/form/id';
import FormNew from 'pages/form/new';

import ChargesList from 'pages/charges-list/charges-list';
import ChargesNew from 'pages/charges-new/charges-new';
import PostingsList from 'pages/postings-list/postings-list';
import PostingsNew from 'pages/postings-new/postings-new';

import Factories from 'pages/factory';
import FactoryNew from 'pages/factory/new';
import FactoryId from 'pages/factory/id';

import Archive from 'pages/archive';
import ArchiveHistory from 'pages/archive/history';
import ArchiveInventory from 'pages/archive/inventory';
import ArchiveSupplies from 'pages/archive/supplies/index';
import ArchiveSuppliesId from 'pages/archive/supplies/[id]';
// import CorrectionsList from 'pages/archive/corrections/corrections-list';

import CabinetSettings from 'pages/cabinet/settings';
import CabinetUsers from 'pages/cabinet/users';

import Faq from 'pages/faq';
import FaqJournalSpread from 'pages/journals/spread';

import HistorySelect from 'pages/history/select';
import HistoryItem from 'pages/history';

import CustomRouter from 'components/custom-router/custom-router';
import browserHistory from 'components/custom-router/browser-history';
import PrivateRoute from 'components/private-route/private-route';

import {AppRoute} from 'constants/routes';
import {AuthStatus} from 'constants/auth-status';

import {getAuthStatus} from 'redux/slices/auth-process/selectors';

const Router = () => {
  const authorizationStatus = useSelector(getAuthStatus);

  return (
    <CustomRouter history={browserHistory}>
      <Routes>
        <Route
          path={AppRoute.Root}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Maps/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Map.ADD}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <MapAdd/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Map.EDIT}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <MapEdit/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Map.ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <MapId/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Map.FIELD}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <MapField/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Inventory.ALL}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Inventory/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Inventory.ADD}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <InventoryAdd/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Inventory.EDIT}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <InventoryEdit/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Journal.AMOUNT}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <JournalAmount/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Journal.SPREAD}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <JournalSpread/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.CHARGES}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesList/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.CHARGES_NEW}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.CHARGES_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.POSTINGS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <PostingsList/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.POSTINGS_NEW}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <PostingsNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.POSTINGS_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <PostingsNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.SUPPLIES}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Supplies/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.SUPPLIES_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              {/* <SuppliesId/> */}
              <SuppliesNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.REALIZATIONS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Realizations/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.REALIZATIONS_NEW}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              {/* <RealizationsNew/> */}
              <New/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.REALIZATIONS_EDIT}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <RealizationEdit/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FORM}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Form/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FORM_NEW}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <FormNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FORM_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <FormId/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FACTORIES}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Factories/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FACTORY_NEW}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <FactoryNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Document.FACTORY_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <FactoryId/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.ALL}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Archive/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.CHARGES}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesList/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.CHARGES_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.POSTINGS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <PostingsList/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.POSTINGS_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <PostingsNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.SUPPLIES}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ArchiveSupplies/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.SUPPLIES_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              {/* <ArchiveSuppliesId/> */}
              <SuppliesNew/>
            </PrivateRoute>
          }
        />

        {/* <Route
          path={AppRoute.Archive.CORRECTIONS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <CorrectionsList/>
            </PrivateRoute>
          }
        /> */}

        <Route
          path={AppRoute.Archive.CORRECTIONS_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ChargesNew/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.HISTORY}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ArchiveHistory/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.HISTORY_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <HistorySelect/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.HISTORY_ITEM}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <HistoryItem/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.INVENTORY}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <ArchiveInventory/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Archive.INVENTORY_ID}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <InventoryEdit/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Cabinet.SETTINGS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <CabinetSettings/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Cabinet.USERS}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <CabinetUsers/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.FAQ.ALL}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <Faq/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.FAQ.JOURNAL_SPREAD}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <FaqJournalSpread/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.History.SELECT}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <HistorySelect/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.History.ITEM}
          element={
            <PrivateRoute authorizationStatus={authorizationStatus}>
              <HistoryItem/>
            </PrivateRoute>
          }
        />

        <Route
          path={AppRoute.Auth.SIGN_IN}
          element={
            authorizationStatus === AuthStatus.AUTH
              ? <Navigate to={AppRoute.Root}/>
              : <Login/>
          }
        />

        <Route
          path={AppRoute.Auth.SIGN_UP}
          element={<SignUp/>}
        />
      </Routes>
    </CustomRouter>
  );
};

export default Router;
