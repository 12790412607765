import React, {Fragment, useContext, useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import axios from 'axios';
import html2canvas from 'html2canvas';

import Input from 'components/input/InputComponent';
import Modal from 'components/Modal';
import Preloader from 'components/preloader/SpinPlaceholderComponent';
import CustomSearchSelect from 'components/custom-search-select/custom-search-select';

import {AppContext} from 'providers/AppContextProvider';
import {getUser} from 'helpers/storage';
import {restrictedGroupsIds} from 'constants/document-data';

import {fetchRealizationAsyncAction} from 'redux/slices/realizations/realizations-api-actions';
import {getRealization} from 'redux/slices/realizations/selectors';
import {fetchGroups} from 'redux/slices/business/businessSlice';
import {fetchAddresses} from 'redux/slices/inventory/inventorySlice';

import tcl from 'styles/pages/[fieldId].module.scss';

const RealizationEdit = () => {
	const {id} = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const router = {
		pathname: window.location.pathname,
		push: (p) => navigate(p),
		query: { type: (new URLSearchParams(window.location.search)).get("type") },
	};

	const {alert, setCrumbs} = useContext(AppContext);

	const userLevel = getUser().accessLevel;

	const addressList = useSelector((state) => state.inventory).addresses.data;
	const groups = useSelector((state) => state.business).groups.data
    .filter((group) => !restrictedGroupsIds.includes(Number(group.id)));

	const toScreenShot = useRef();
	const [isPreloaderActive, setIsPreloaderActive] = useState(false);

	const RealizationData = useSelector(getRealization);

	useEffect(() => {
		if (RealizationData.length) {
			setData(RealizationData);
			setDocumentNumber(RealizationData[0].document_number);
			setDocumentDate(RealizationData[0].document_date);
			setComment(RealizationData[0].comment);
		}
	}, [RealizationData.length]);
	
	// Параметры документа
	const [documentNumber, setDocumentNumber] = useState('');
	const [documentDate, setDocumentDate] = useState('');
	const [comment, setComment] = useState('');
	
	// Данные с сервера
	const [data, setData] = useState([]);
	const [total, setTotal] = useState(0);
	
	// Классы таблицы
	const tableClasses = [tcl.mainTable, tcl.top0, "table", "table-responsive"];
	
	// Асинхронные данные из Бизнес.ру
	const [plantsNames, setPlantsNames] = useState([]);
	const [plantsArticles, setPlantsArticles] = useState([]);
	
	// Поле сортировки и направление
	const [sorted, setSorted] = useState("name");
	const [sortDir, setSortDir] = useState(true);
	
	// Модальное окно
	const [modalIsActive, setModalIsActive] = useState(false);
	
	// Файлы в примечании
	const [files, setFiles] = useState(RealizationData.length ? RealizationData[0].files : '');
	
	// Интервал автосохранения
	const autoSaveInterval = 300000;
	
	useEffect(() => {
		// Если доступ просмотровый, запрет редактирования
		if(userLevel < 2){
			data[0].provided = 1;
			data[0].done = 1;
		}
		
		// Запрос наименований растений
		axios.get("good/all").then(response => {
			setPlantsNames(response.data.result.goods.sort((a, b) => {
				if(a.name === b.name) return 0;
				else return a.name > b.name ? 1 : -1;
			}));
			setPlantsArticles(response.data.result.articles);
		});
	}, []);
	
	// Запись хлебных крошек
	useEffect(() => {
		if (RealizationData && RealizationData.length && RealizationData[0].document_number) {
			setCrumbs([
				{name: "Отгрузки", url: "/realizations"},
				{name: "№ " + RealizationData[0].document_number + " (редактирование)", url: ""}
			]);
		}
	}, [RealizationData]);
	
	// Подсчет общего кол-ва
	useEffect(() => {
		if (data && data.length) {
			let totalAll = 0;
			data.forEach(dt => {
				dt.userPositions.forEach(up => {
					if(!isNaN(parseInt(up.amount))) totalAll += parseInt(up.amount);
				});
			});
			setTotal(totalAll);
			
			// Автосохранение
			for(let i = 1; i < 1000; i++) clearInterval(i);
			setInterval(() => {
				if(data[0].provided === 0) editRealization();
			}, autoSaveInterval);
		}
	}, [data]);
	
	// Сохранение данных
	const editRealization = async(redirect = true) => {
		// Валидация
		let formError = false;
		let errorNotified = false;
		const copy = structuredClone(data);
		copy.forEach((c, i) => {
			let error = false;
			
			// Проверка номера документа
			if(documentNumber === ""){
				formError = true;
				errorNotified = true;
				alert("Введите номер документа", "danger", 2000);
			}
			
			if(error){
				data[i].isError = true;
				setTimeout(() => {
					data[i].isError = false;
				}, 5000);
			}
		});
		
		if(formError) return false;
		
		// Сохранение документа на отгрузку
		return axios.post("realizations/saveRealizationToFile/" + data[0].realization_id, {
			realization: copy,
			head: {number: data[0].number, customer: data[0].customer},
			documentNumber: documentNumber,
			documentDate: documentDate,
			filename: data[0].filename,
			provided: !redirect,
			comment: comment
		}).then(response => {
			return screenShot().then(() => {
				if(redirect){
					alert("Успешно сохранено", "success", 2000);
				}
				return true;
			});
		}).catch(() => {
			alert("Ошибка сохранения", "danger", 2000);
			return false;
		});
	}
	
	// Удаление отгрузки
	const deleteRealization = () => {
		alert("", "default", 6000, [], true);
		
		alert("Удалить отгрузку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.delete("realizations/deleteRealization/" + data[0].realization_id).then(() => {
						alert("Отгрузка успешно удалена", "success", 2000);
						router.push("/realizations");
					}).catch(() => {
						alert("Ошибка удаления", "danger", 2000);
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Проведение отгрузки
	const provide = () => {
		alert("", "default", 6000, [], true);
		editRealization(false).then(saved => {
			if(!saved) return;
			
			axios.post("realizations/provide/" + id).then(() => {
				const copy = structuredClone(data);
				copy[0].provided = 1;
				setData(copy);
				alert("Перемещено на Основной склад", "success", 2000);
			}).catch(() => {
				alert("Ошибка перемещения", "danger", 2000);
			});
		});
	}
	
	// Завершение отгрузки
	const setDone = () => {
		setIsPreloaderActive(true);
		axios.get("realizations/setDone/" + data[0].realization_id).then(() => {
			setIsPreloaderActive(false);
			router.push("/realizations");
			alert("Готово. Не забудьте оформить эту отгрузку в базе!", "success", 2000);
		}).catch(() => {
			setIsPreloaderActive(false);
			alert("Ошибка проведения отгрузки", "danger", 2000);
		});
	}
	
	// Отмена отгрузки
	const deny = () => {
		alert("Отменить отгрузку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.post("realizations/provide/" + data[0].realization_id, {
						deny: 1
					}).then(() => {
						const copy = structuredClone(data);
						copy[0].provided = 0;
						
						// Возврат остатков по складам
						copy.forEach((cp, index) => {
							cp.userPositions.forEach((up, ind) => {
								if(parseInt(up.amount) > 0){
									copy[index].amounts[ind].amount += parseInt(up.amount);
									copy[index].totalAmount += parseInt(up.amount);
								}
							});
						});
						
						setData(copy);
						alert("Отгрузка успешно отменена", "success", 2000);
					}).catch(() => {
						alert("Ошибка отмены", "danger", 2000);
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Добавление файла к форме
	const attachFile = (e) => {
		// Сборка FormData
		const formData = new FormData();
		Object.values(e.target.files).forEach((file, index) => {
			formData.append("file_" + index, file);
		});
		
		axios.post("realizations/setFile/" + data[0].realization_id, formData, {
			headers: {
				"Content-type": "multipart/form-data",
			}
		}).then(response => {
			alert(response.data.files.length > 1 ? "Файлы загружены" : "Файл загружен", "success");
			const fl = [...files, response.data.files];
			setFiles(fl[0]);
		}).catch(() => {
			alert("Ошибка добавления файла", "danger", 2000);
		});
	}
	
	// Удаление файла
	const removeFile = (url) => {
		alert("Удалить файл?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					axios.post("realizations/removeFile", {filename: url}).then(() => {
						alert("Файл удален", "success", 2000);
						const copy = structuredClone(files);
						copy.forEach((cp, ind) => {
							if(cp.url === url) copy.splice(ind, 1);
						});
						setFiles(copy);
					});
				}
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Метод для отправки скриншота
	const screenShot = async() => {
		return true;
		const canvas = await html2canvas(toScreenShot.current, {useCORS: true}).then(canv => {
			const image = canv.toDataURL("image/png", 1.0);
			axios.post("realizations/editRealizationThumb/" + data[0].realization_id, {thumb: image});
		});
	}
	
	// Метод изменения наименования
	const changeName = (index, name, value) => {
		if(name === "amounts" && data[index].name === "") return;
		
		const copy = structuredClone(data);
		
		// Проверка дублирующей позиции
		let error = false;
		copy.forEach(cp => {
			if(cp[name] === value) error = true;
		});
		
		// Вывод ошибки, если это не группа
		if(error && name !== "b_group"){
			alert("Такой товар уже есть в списке!", "danger", 2000);
			copy.splice(index, 1);
		} else copy[index][name] = value;
		
		// Особый флаг для услуг
		if(name === "b_group" && value === "Услуги"){
			copy[index].isUsl = true;
			copy[index].userPositions[0].amount = 1;
		}
		
		setData(copy);
	}
	
	// Изменение позиций
	const onChangePos = (index, name, value) => {
		const copy = structuredClone(data);
		
		// Изменение остатков на полях в таблице
		if(!isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"])) && name === "amount"){
			if(isNaN(parseInt(copy[index.index].amounts[index.ind]["amount"]))) copy[index.index].amounts[index.ind]["amount"] = 0;
			if(!isNaN(parseInt(copy[index.index].userPositions[index.ind]["amount"])))
				copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) + parseInt(copy[index.index].userPositions[index.ind]["amount"]);
			if(!isNaN(parseInt(value)))
				copy[index.index].amounts[index.ind]["amount"] = parseInt(copy[index.index].amounts[index.ind]["amount"]) - parseInt(value);
		}
		
		// Изменение стоимости услуги или просто позиции
		if(name === "address_amount") copy[index.index].amounts[index.ind].address = value;
		else copy[index.index].userPositions[index.ind][name] = value;
		
		setData(copy);
	}
	
	// Запись бригадира
	const setEmployee = (name, value) => {
		const copy = structuredClone(data);
		copy[0].employee = value;
		setData(copy);
	}
	
	// Скролл вниз при раскрытии списка с растениями внизу страницы
	const scrollPlus = useRef(null);
	const scrollCustomSelect = (index) => {
		if(data.length - 1 - index < 4)
			scrollPlus.current.scrollIntoView({behavior: "smooth"});
	}
	
	// Новая строка
	const addLine = () => {
		const copy = structuredClone(data);
		copy.push({
			name: "",
			totalAmount: 0,
			amounts: [{
				amount: "",
				address: ""
			}],
			userPositions: [{
				amount: ""
			}]
		});
		setData(copy);
		setTimeout(() => {
			scrollCustomSelect(copy.length - 1);
		}, 200);
	}
	
	// Удаление строки
	const deleteLine = (index) => {
		alert("Удалить строку?", "danger", 0, [
			{
				text: "Да",
				handler: function(){
					const copy = structuredClone(data);
					copy[index.index].amounts.splice(index.i, 1);
					copy[index.index].userPositions.splice(index.i, 1);
					if(!copy[index.index].amounts.length) copy.splice(index.index, 1);
					setData(copy);
				},
				needToCloseImmediately: true
			},
			{
				text: "Нет",
				handler: function(){
					alert("", "default", 1);
				}
			}
		]);
	}
	
	// Функция сортировки растений
	const sortPlants = (name) => {
		setSortDir(!sortDir);
		
		const pl = structuredClone(data);
		
		pl.sort((a, b) => {
			if(a[name] === b[name]) return 0;
			else if(!sortDir === true) return a[name] > b[name] ? 1 : -1;
			else return a[name] < b[name] ? 1 : -1;
		});
		
		setData(pl);
		setSorted(name);
	}
	
	// Фильтрация наименований в зависимости от группы
	const filterNames = (groupId = 0) => {
		if(groupId === 0) return plantsNames;
		
		const filtered = plantsNames.filter(name => {
			return name.b_group_id === groupId;
		});
		return filtered.sort((a, b) => {
			if(a.name === b.name) return 0;
			else return a.name > b.name ? 1 : -1;
		});
	}
	
	// Фильтрация артикулов в зависимости от группы
	const filterArticles = (groupId = 0) => {
		if(groupId === 0) return plantsArticles;
		
		return plantsArticles.filter(article => {
			return article.b_group_id === groupId;
		});
	}

	const requestForDocumentDetails = async () => {
		setIsPreloaderActive(true);
		await dispatch(fetchRealizationAsyncAction(id));
		setIsPreloaderActive(false);
	};

	useEffect(() => {
		requestForDocumentDetails();
	}, [id]);

	useEffect(() => {
		if (!groups.length) {
      dispatch(fetchGroups());
    }
		if (!addressList.length) {
			dispatch(fetchAddresses());
		}
	}, []);
	
	return (
		<>
			<div className={tcl.floatPanel + " " + tcl.controlsPanel}>
				{
					data.length && data[0].provided === 0 && userLevel > 1 && (
						<span
							className="roundIcon dangerIcon material-icons"
							onClick={deleteRealization}
							title="Удалить отгрузку"
						>
							delete_sweep
						</span>
					)
				}
				
				{
					data.length && (
						<span
							className="roundIcon material-icons"
							onClick={() => router.push("/FILES/" + data[0].filename).then(() => router.push("/realizations/edit/" + data[0].realization_id))}
							title="Скачать"
						>
							download
						</span>
					)
				}
				
				{
					data.length && data[0].provided === 0 && userLevel > 1
						? <span
								className="roundIcon material-icons"
								onClick={() => alert("Провести в Системе?", "default", 0, [
									{
										text: "Да",
										handler: function(){
											provide();
										}
									},
									{
										text: "Нет",
										handler: function(){
											alert("", "default", 1);
										}
									}
								])}
								title="Провести в Системе"
							>
								checklist
							</span>
						: userLevel > 1 && (
							<span
								className="roundIcon dangerIcon material-icons"
								onClick={deny}
								title="Отменить"
							>
								undo
							</span>
						)
				}
				
				{
					data.length && data[0].provided === 0 && userLevel > 1
					? (
						<span className="roundIcon material-icons" onClick={() => alert("Сохранить?", "default", 0, [
							{
								text: "Да",
								handler: function(){
									alert("", "default", 6000, [], true);
									editRealization();
								}
							},
							{
								text: "Нет",
								handler: function(){
									alert("", "default", 1);
								}
							}
						])} title="Сохранить">save</span>
					)
					: userLevel > 1 && (
						<span className="roundIcon material-icons" onClick={() => alert("Провести в Базе?", "default", 0, [
							{
								text: "Да",
								handler: function(){
									alert("", "default", 6000, [], true);
									setDone();
								}
							},
							{
								text: "Нет",
								handler: function(){
									alert("", "default", 1);
								}
							}
						])} title="Провести в Базе">done</span>
					)
				}
				
				<span
					className="roundIcon material-icons"
					onClick={() => setModalIsActive(true)}
					title="Примечание"
				>
					maps_ugc
				</span>
				<span
					className="roundIcon material-icons"
					onClick={() => window.open("/faq/realization", "_blank")}
					title="Инструкция"
				>
					quiz
				</span>
			</div>
			
			<div className="stickyContainer" ref={toScreenShot}>
				<p style={{margin: ".3rem 0 0 .5rem"}}>{(data.length && data[0].number) || ''}</p>
				<p style={{margin: ".3rem 0 .3rem .5rem"}}>{(data.length && data[0].customer) || ''}</p>
				
				<table className={tableClasses.join(" ")} style={data.length && data.length === 0 ? {display: "none"} : {}}>
					<thead className={tcl.top0 + " theadBordered thead-dark"}>
						<tr>
							<th colSpan="2" className={tcl.borderNone}>
								Отгрузка (реализация) №&nbsp;
								{
									data.length && data[0] && data[0].provided === 0
										? (
											<Input
												type="text"
												name="documentNumber"
												autocomplete="off"
												placeholder="№"
												value={documentNumber}
												setValue={(name, value) => setDocumentNumber(value)}
											/>
										)
										: documentNumber
								}
							</th>
							<th className={tcl.borderNone}>
								от&nbsp;{documentDate.split(' ').slice(0, -1).join(' ')}
							</th>
							<th>
								{
									data.length && data[0].author &&
									<>
										Автор:&nbsp;
										{data[0].author}
										<br/>
									</>
								}
								Бригадир:&nbsp;
								{
									data.length && (
										<Input
											type="text"
											name="employee"
											value={data[0].employee}
											setValue={setEmployee}
										 	placeholder="Бригадир"
											title={data[0].employee}
										/>
									)
								}
							</th>
							<th colSpan="3">Текущие остатки в Системе:</th>
							<th rowSpan="3">Кол-во<br/>по заказу</th>
							<th colSpan="2">Переместить на Основной склад</th>
							{data.length && data[0].provided === 0 && <th rowSpan="3" className={tcl.borderNone}/>}
						</tr>
						<tr>
							<th rowSpan="2">№</th>
							<th
								rowSpan="2"
								className={sorted === "name" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("name")}
							>
								<span>Название</span>
							</th>
							<th
								rowSpan="2"
								className={sorted === "article" ? "sort sorted" : "sort"}
								onClick={() => sortPlants("article")}
							>
								<span>Артикул</span>
							</th>
							<th rowSpan="2">Группа</th>
							<th rowSpan="2">Всего:</th>
							<th colSpan="2">В том числе:</th>
							<th rowSpan="2">Кол-во</th>
							<th rowSpan="2">Со склада</th>
						</tr>
						<tr>
							<th>Кол-во</th>
							<th>Адрес</th>
						</tr>
					</thead>
					<tbody>
						{
							data.length && data.length > 0 && data.map((line, index) =>
								<Fragment key={index.toString()}>
									{
										line.amounts.map((ln, i) =>
											<tr key={i.toString()} className={line.isError ? tcl.error : ""}>
												{
													i === 0 &&
													<>
														<td rowSpan={line.amounts.length}>{index + 1}</td>
														<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='name'
																		defaultValue={line.name}
																		options={filterNames(line.b_group_id)}
																		onChange={(value) => changeName(index, 'name', value)}
																		// onInput={checkNameRepeated}
																		// onFocus={checkNameRepeated}
																		placeholder='Наименование'
																	/>
																)
																: line.name
															}
														</td>
														<td rowSpan={line.amounts.length} onClick={() => scrollCustomSelect(index)}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='article'
																		defaultValue={line.article}
																		options={filterArticles(line.b_group_id)}
																		onChange={(value) => changeName(index, 'article', value)}
																		// onInput={checkNameRepeated}
																		// onFocus={checkNameRepeated}
																		placeholder='Артикул'
																	/>
																)
																: line.article
															}
														</td>
														<td rowSpan={line.amounts.length}>
															{
																line.name === ""
																? (
																	<CustomSearchSelect
																		inputName='b_group'
																		defaultValue={line.b_group}
																		options={groups}
																		onChange={(value) => changeName(index, 'b_group', value)}
																		// onInput={checkNameRepeated}
																		// onFocus={checkNameRepeated}
																		placeholder='Группа'
																	/>
																)
																: line.b_group
															}
														</td>
														<td rowSpan={line.amounts.length} className={tcl.right}>
															{!line.isUsl && new Intl.NumberFormat("ru-RU").format(line.totalAmount)}
														</td>
													</>
												}
												
												<td className={tcl.right}>
													{!line.isUsl && new Intl.NumberFormat("ru-RU").format(ln.amount)}
												</td>
												<td>{!line.isUsl && ln.address}</td>
												
												{
													i === 0 && (
														<td rowSpan={line.amounts.length} className={tcl.right}>
															{line.orderAmount ? new Intl.NumberFormat("ru-RU").format(line.orderAmount) : 0}
														</td>
													)
												}
												
												<td className={tcl.right}>
													{
														data[0].provided === 0 && line.isUsl !== true
														? (
															<Input
																type="number" name="amount"
																placeholder="Кол-во"
																value={line.userPositions[i].amount}
																setValue={onChangePos}
																currentIndex={{index: index, ind: i}}
															/>
														)
														: new Intl.NumberFormat("ru-RU").format(line.userPositions[i].amount)
													}
												</td>
												
												<td>
													{
														line.isUsl !== true && data[0].provided === 0
														? (
															<CustomSearchSelect
																inputName='address_amount'
																defaultValue={ln.address}
																options={addressList}
																onChange={(value) => onChangePos({index: index, ind: i}, 'address_amount', value)}
																// onInput={checkNameRepeated}
																// onFocus={checkNameRepeated}
																placeholder='Адрес'
															/>
														)
														: ln.address
													}
												</td>
												
												{
													data[0].provided === 0 && (
														<>
															<td className="iconed">
															<span
																className="roundIcon material-icons"
																onClick={() => deleteLine({index: index, i: i})}
																title="Удалить строку">delete_outline</span>
															</td>
														</>
													)
												}
											</tr>
										)
									}
								</Fragment>
							)
						}
						{
							data.length && data[0].provided === 0 && (
								<tr className="adding" ref={scrollPlus}>
									<td className="iconed">
										<span
											className="roundIcon material-icons"
											onClick={addLine}
											title="Добавить наименование"
										>
											add
										</span>
									</td>
								</tr>
							)
						}
					</tbody>
				</table>
			</div>
			
			<Modal isActive={modalIsActive} setIsActive={setModalIsActive}>
				{
					data.length && data[0].done
					? (
						<>
							<textarea placeholder="Примечание" onChange={(e) => setComment(e.target.value)}>
								{comment}
							</textarea>
							<input type="file" name="files[]" onChange={attachFile} multiple/>
						</>
					)
					: comment
				}
				{
					files && files.length && files.map((file) =>
						<div className="fileIconGroup">
							<span
								className="material-icons remove"
								title="Удалить"
								onClick={() => removeFile(file.url)}
							>
								cancel
							</span>
							<span
								className="material-icons file"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								description
							</span>
							<span
								className="name"
								onClick={() => file.ext === "pdf" ? window.open(file.url, "_blank") : router.push(file.url).then(() => router.push(router.asPath))}
							>
								{file.name}
							</span>
						</div>
					)
				}
			</Modal>
			<Preloader isActive={isPreloaderActive}/>
		</>
	);
};

export default RealizationEdit;
