export const ApiRoute = {
  Realizations: {
    GET_REALIZATION: '/realizations/getRealization'
  },
  Fields: {
    GET_FORMS: '/fields/getForms',
    GET_FORM: '/fields/getForm',
    SAVE_FORM: '/fields/saveForm',
    UPDATE_FORM: '/fields/editForm',
    DELETE_FORM: '/fields/deleteForm',
    SET_INITIAL_AMOUNTS: '/fields/checkAmount',
    SET_INITIAL_HISTORY_AMOUNTS: '/fields/checkHistoryAmounts',
    REFRESH_AMOUNTS: '/fields/updateFieldsAmountByGoods',
    REFRESH_POSTING_DOCUMENT_AMOUNTS: '/fields/updatePostingDocumentFieldsAmountByGoods',
    PROVIDE_FORM: '/fields/provideForm',
    SEND_COMMENT_FILES: '/fields/setFormFile',
    DELETE_COMMENT_FILE: '/fields/removeFormFile',
    GET_GOODS_BY_STORAGE: '/fields/getGoodsByStorage',
    COPY_FORM_FILES: '/fields/copyFormFiles',
    PROCESS_FORM: '/fields/processForm',
    PROCESS_FORM_WITH_HISTORY_REWRITE: '/fields/processFormWithHistoryRewrite'
  },
  Map: {
    GET_ALL_MAPS: '/map/getMaps',
    GET_MAP: '/map/getMap',
    GET_ALL_FIELDS: '/map/getAllFields',
    ADD: '/map/add',
    DELETE_FIELD: '/map/deleteField',
    GET_ALL_ADDRESSES: '/map/getAllAddresses',
    EDIT_THUMB: '/map/editThumb'
  },
  History: {
    CURRENT_NAMES: '/history/getCurrentNames',
    ARCHIVE_NAMES: '/history/getArchiveNames',
    ADD_DATA: '/history/addData'
  },
  Business: {
    GROUPS: '/businessRu/getGroups',
    GOODS: '/businessRu/getGoods',
    STORES: '/businessRu/getStores',
    SUPPLIES: '/businessRu/getSupplies',
    SUPPLY: '/businessRu/getSupply',
    SAVE_SUPPLY: '/businessRu/editSupply',
    ADD_SUPPLIES_ITEMS: '/businessRu/addSuppliesItems',
    DELETE_SUPPLIES_ITEMS: '/businessRu/deleteSuppliesItems',
    SET_COMMENT_FILES: '/businessRu/setSupplyCommentFiles',
    DELETE_COMMENT_FILE: '/businessRu/removeSupplyCommentFile'
  }
};

const Map = {
  GET_ALL_MAPS: '/map/getMaps',
  GET_MAP: '/map/getMap',
  GET_ALL_FIELDS: '/map/getAllFields',
  ADD: '/map/add',
  DELETE_FIELD: '/map/deleteField',
  GET_ALL_ADDRESSES: '/map/getAllAddresses',
  EDIT_THUMB: '/map/editThumb'
};

const fields = {
  HEADS: '/fields/updateFieldHead',
  DATA_LINE: '/fields/updateFieldDataLine',
  DELETE_DATA: '/fields/deleteFieldData',
  PRINT_TABLE: '/fields/printFieldTable',
};

const User = {
  CHECK_AUTH: '/auth/validateToken',
  LOGIN: '/auth/getToken',
  REGISTER: '/auth/register',
  INFO: '/user/info',
};

const journals = {
  AMOUNT_DATA: '/journals/amountData',
  AMOUNT_ALL: '/journals/amountAll',
  JOURNAL_NAME: '/journals/journalName',
  SPREAD_DATA: '/journals/spreadData',
};

const Inventory = {
  ALL: '/inventory/all',
  // POST_FILE: '/inventory/getAmounts', // выборка учётного кол-ва с площадок
  POST_FILE: '/inventory/getAmountsFromHistory', // выборка учётного кол-ва из истории
  ITEMS: '/inventory/getItems',
  EDIT_FILE: '/inventory/editFile',
  SAVE: '/inventory/save',
  EDIT_THUMB: '/inventory/editInventoryThumb',
  PROVIDE: '/inventory/provide',
  EDIT_FILES_BRU: '/inventory/editFilesBru',
  REMOVE: '/inventory/remove',
  ARCHIVES: '/inventory/getArchives',
  COPY: '/inventory/copyInventory',
  SET_COMMENT: '/inventory/setComment',
  SET_COMMENT_FILE: '/inventory/setCommentFile'
};

const History = {
  CURRENT_NAMES: 'history/getCurrentNames',
  ARCHIVE_NAMES: '/history/getArchiveNames'
};

const Good = {
  ALL: '/good/all',
  SYNC: '/good/sync'
};

const business = {
  GROUPS: '/businessRu/getGroups',
  GOODS: '/businessRu/getGoods',
  STORES: '/businessRu/getStores',
  SUPPLIES: '/businessRu/getSupplies',
  SUPPLY: '/businessRu/getSupply',
};

const doc = {
  REALIZATIONS: '/realizations/getRealizations',
  FACTORIES: '/factory/all',
  FACTORY: '/factory/getFactory',
}

export {
  Map,
  fields,
  User,
  journals,
  Inventory,
  History,
  Good,
  business,
  doc,
};
